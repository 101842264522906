export default class Partners {
  constructor() {
    const buttons = $('.partner-categories button')
    const partners = $(
      '.partner[data-category], .partners__text a[data-category]',
    )

    buttons.on('click', (event) => {
      buttons.removeClass('active')

      const target = $(event.currentTarget)
      target.addClass('active')

      const category = target.text()
      const logos = $('.partners__logos')

      if (category === 'All') {
        partners.show()
        logos.show()
      } else {
        partners.hide()
        this.toggleLogos(category)
        $(
          `.partner[data-category="${category}"], a[data-category="${category}"]`,
        ).show()
      }
    })
  }

  toggleLogos(category) {
    const logos = $('.partners__logos')
    const partners = logos.find(`.partner[data-category="${category}"]`)

    if (partners.length) {
      logos.show()
    } else {
      logos.hide()
    }
  }
}
