export default class Quotes {
  constructor() {
    $('h1, h2, h3, h4, h5, h6, p, li, a').each((_, elem) => {
      const target = $(elem)

      this.changeQuotes(target)
    })
  }

  changeQuotes(elem) {
    const children = elem.children()

    if (!elem.parents().hasClass('staff-member__quote')) {
      if (!children.length) {
        let newText = elem.text()

        newText = newText
          .replace(/(" )/gm, '” ')
          .replace(/^(")/gm, '<span class="indent">“</span>')
          .replace(/( ")/gm, ' “')
          .replace(/(")$/gm, '”')
          .replace(/(" )/gm, '” ')
          .replace(/("\.)/gm, '”.')

        elem.html(newText)
      } else {
        children.each((_, child) => {
          if (
            [
              'H1',
              'H2',
              'H3',
              'H4',
              'H5',
              'H6',
              'P',
              'LI',
              'A',
              'B',
              'STRONG',
              'I',
              'EM',
            ].includes(child.tagName)
          ) {
            this.changeQuotes($(child))
          }
        })
      }
    }
  }
}
