export default class News {
  constructor() {
    $('.latest-news__list--mobile').slick({
      infinite: false,
      arrows: false,
      dots: true,
      slidesToScroll: 1,
      variableWidth: true,
    })

    $('.latest-news__list--mobile').on('setPosition', () => {
      $('.latest-news__list--mobile .slick-slide li').height('auto')
      var stHeight = $('.latest-news__list--mobile .slick-track').height()
      $('.latest-news__list--mobile .slick-slide li').css(
        'height',
        stHeight + 'px',
      )
    })

    $(window).on('resize', () => {
      $('.latest-news__list--mobile .slick-slide li').height('auto')
      var stHeight = $('.latest-news__list--mobile .slick-track').height()
      $('.latest-news__list--mobile .slick-slide li').css(
        'height',
        stHeight + 'px',
      )
    })

    $('#news-category').on('change', (e) => {
      const value = e.target.value

      $.ajax({
        url: '/news_posts.js',
        method: 'GET',
        data: { tag: value },
      })
    })
  }
}
