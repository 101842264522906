export default class Staff {
  constructor() {
    $('.staff-member__bio-wrapper button').on('click', (e) => {
      const target = $(e.currentTarget)
      target.toggleClass('open')
      target.next().slideToggle(400)
      target.parents('.staff-member').toggleClass('above')
    })
  }
}
