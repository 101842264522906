export default class Spotlight {
  constructor() {
    $('.spotlight-wrapper__slides').each((_, target) => {
      const slides = $(target)
      const nav = slides.next().find('.gallery__buttons button')
      const prev = slides.next().find('.gallery__prev')
      const next = slides.next().find('.gallery__next')

      slides.slick({
        arrows: false,
        adaptiveHeight: true,
        touchThreshold: 20,
      })

      slides.on('beforeChange', (_, slick, from, to) => {
        nav.removeClass('active')
        nav.eq(to).addClass('active')
      })

      nav.on('click', (event) => {
        const target = $(event.currentTarget)
        nav.removeClass('active')
        target.addClass('active')

        const imageIndex = target.index()
        slides.slick('slickGoTo', imageIndex)
      })

      prev.on('click', () => {
        slides.slick('slickPrev')
      })

      next.on('click', () => {
        slides.slick('slickNext')
      })
    })
  }
}
