export default class Gallery {
  constructor() {
    $('.gallery, .card-gallery').each((i, target) => {
      const gallery = $(target)
      const slides = gallery.find('.gallery__slides')
      // const nav = gallery.find('.gallery__buttons button')
      const prev = gallery.find('.gallery__prev')
      const next = gallery.find('.gallery__next')

      if (gallery.hasClass('gallery')) {
        slides.slick({
          arrows: false,
          variableWidth: true,
          centerMode: true,
          touchThreshold: 20,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                centerMode: false,
              },
            },
          ],
        })
      } else {
        slides.slick({
          arrows: false,
          variableWidth: true,
          infinite: false,
          touchThreshold: 20,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                centerMode: true,
                infinite: true,
              },
            },
          ],
        })
      }

      if (!gallery.hasClass('gallery')) {
        slides.on('beforeChange', (_, slick, from, to) => {
          // nav.removeClass('active')
          // nav.eq(to).addClass('active')

          if (to !== 0) {
            prev.removeClass('disabled')
          } else {
            prev.addClass('disabled')
          }
        })

        slides.on('afterChange', (_, slick) => {
          const lElRect =
            slick.$slides[slick.slideCount - 1].getBoundingClientRect()
          const rOffset = lElRect.x + lElRect.width
          const wraRect = $('.slick-list', slides)
            .get(0)
            .getBoundingClientRect()

          if (rOffset < wraRect.x + wraRect.width) {
            next.addClass('disabled')
          } else {
            next.removeClass('disabled')
          }
        })
      }

      // nav.on('click', (event) => {
      //   const target = $(event.currentTarget)
      //   // nav.removeClass('active')
      //   target.addClass('active')

      //   const imageIndex = target.index()
      //   slides.slick('slickGoTo', imageIndex)
      // })

      prev.on('click', () => {
        slides.slick('slickPrev')
      })

      next.on('click', () => {
        slides.slick('slickNext')
      })
    })
  }
}
