export default class Menu {
  constructor() {
    this.hamburger = $('.hamburger__wrapper')
    this.nav = $('.header__nav')
    this.initMenu()
  }

  initMenu() {
    this.hamburger.on('click', () => {
      this.hamburger.children().toggleClass('active')
      this.nav.slideToggle(300)
    })

    const childrenToggles = document.querySelectorAll('.children-toggle')

    Array.from(childrenToggles).forEach((toggle) => {
      toggle.addEventListener('click', () => {
        toggle.classList.toggle('open')
        $(toggle).next().slideToggle()
      })
    })
  }
}
