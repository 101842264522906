import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import objectFitVideos from 'object-fit-videos'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

objectFitImages()
objectFitVideos()

import Menu from './menu'

new Menu()

import Accordions from './accordions'
import Alert from './alert'
import Diamonds from './diamonds'
import Events from './events'
import Formbuilder from './formbuilder'
import Gallery from './gallery'
import News from './news'
import Parallax from './parallax'
import Partners from './partners'
import Quotes from './quotes'
import Spotlight from './spotlight'
import Staff from './staff'
import Stats from './stats'
import Video from './video'

new Accordions()
new Alert()
new Diamonds()
new Events()
new Formbuilder()
new Gallery()
new News()
new Parallax()
new Partners()
new Quotes()
new Spotlight()
new Staff()
new Stats()
new Video()
