export default class Diamonds {
  constructor() {
    $('.diamond__name').on('click', (event) => {
      const target = $(event.currentTarget)
      target.next().slideToggle(300)
      target.parent().siblings().find('.diamond__about').slideUp(300)
    })

    $(window).on('scroll', () => {})
  }
}
