export default class Parallax {
  constructor() {
    const homeHero = Array.from(document.querySelectorAll('.home-hero'))
    const pageHero = Array.from(document.querySelectorAll('.page-hero-image'))
    const diamonds = Array.from(document.querySelectorAll('.diamond-grid'))

    if (homeHero.length) {
      window.addEventListener('scroll', () => this.heroOnScroll(homeHero))
      window.addEventListener('resize', () => this.heroOnScroll(homeHero))
    }

    if (pageHero.length) {
      window.addEventListener('scroll', () => this.pageOnScroll(pageHero))
      window.addEventListener('resize', () => this.pageOnScroll(pageHero))
    }

    if (diamonds.length) {
      document.addEventListener('DOMContentLoaded', () =>
        this.diamondsOnScroll(diamonds),
      )
      window.addEventListener('scroll', () => this.diamondsOnScroll(diamonds))
      window.addEventListener('resize', () => this.diamondsOnScroll(diamonds))
    }
  }

  heroOnScroll(heroes) {
    const headerHeight = document.querySelector('header').offsetHeight

    heroes.forEach((hero) => {
      const top = hero.getBoundingClientRect().top
      const bottom = hero.getBoundingClientRect().bottom
      const amountToScroll = hero.offsetHeight + headerHeight
      const distance = window.pageYOffset / amountToScroll

      const background = hero.querySelector('.home-hero__background')

      let percent = 0

      if (bottom < 0) {
        percent = 1
      } else if (top < headerHeight) {
        percent = distance <= 1 ? distance : 1

        background.style.top = `${-160 * percent}px`
      } else {
        percent = 0
      }
    })
  }

  pageOnScroll(heroes) {
    // const headerHeight = document.querySelector('header').offsetHeight

    heroes.forEach((hero) => {
      const top = hero.getBoundingClientRect().top
      const bottom = hero.getBoundingClientRect().bottom
      const amountToScroll = hero.offsetTop + hero.offsetHeight
      const distance = window.pageYOffset / amountToScroll

      const background = hero.querySelector('.page-hero-image__wrapper img')

      let percent = 0

      if (bottom < 0) {
        percent = 1
      } else if (top < hero.offsetTop) {
        percent = distance <= 1 ? distance : 1

        background.style.top = `${-160 * percent}px`
      } else {
        percent = 0
      }
    })
  }

  diamondsOnScroll(diamonds) {
    const windowHeight = window.innerHeight

    diamonds.forEach((diamond) => {
      const top = diamond.getBoundingClientRect().top
      const bottom = diamond.getBoundingClientRect().bottom
      const amountToScroll = diamond.offsetHeight + windowHeight
      const distance = bottom / amountToScroll

      let percent = 0

      if (bottom < 0) {
        percent = 1
      } else if (top < windowHeight && bottom > 0) {
        percent = 1 - (distance <= 1 ? distance : 1)
      } else {
        percent = 0
      }

      let totalMovement = 80
      const marginPercent = totalMovement * percent
      const moved = totalMovement / 2 - marginPercent

      diamond.style.transform = `translateY(${moved}px)`
    })
  }
}
