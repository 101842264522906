export default class Accordions {
  constructor() {
    $('.accordions').each((_, target) => {
      const accordion = $(target)

      accordion.find('.accordion__title').on('click', (e) => {
        $(e.currentTarget).toggleClass('open').next().slideToggle(400)

        this.disableButtons(accordion)
      })

      accordion.find('.expand').on('click', () => {
        accordion
          .find('.accordion__title')
          .addClass('open')
          .next()
          .slideDown(400)

        this.disableButtons(accordion)
      })

      accordion.find('.collapse').on('click', () => {
        accordion
          .find('.accordion__title')
          .removeClass('open')
          .next()
          .slideUp(400)

        this.disableButtons(accordion)
      })
    })
  }

  disableButtons(accordion) {
    const titleLength = accordion.find('.accordion__title').length
    const openLength = accordion.find('.accordion__title.open').length

    if (openLength > 0) {
      accordion.find('.collapse').removeClass('disabled')
    } else {
      accordion.find('.collapse').addClass('disabled')
    }

    if (openLength === titleLength) {
      accordion.find('.expand').addClass('disabled')
    } else {
      accordion.find('.expand').removeClass('disabled')
    }
  }
}
