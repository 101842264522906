import moment from 'moment/src/moment'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'

export default class Events {
  constructor() {
    $('.upcoming-events__list--mobile').slick({
      arrows: false,
      dots: true,
      infinite: false,
      variableWidth: true,
    })

    this.calendar = null
    this.calendarEl = document.getElementById('calendar')
    this.singleDay = $('#single-day')
    this.listView = $('#list-view')
    this.pastView = ''
    this.selectedDate = moment().format()

    this.filters = {
      query: '',
      category: '',
    }

    if (this.calendarEl) {
      this.initCalendar()
    }

    this.viewToggle = $('.event-views__button')

    this.viewToggle.on('click', (e) => {
      const target = $(e.currentTarget || e.target)
      this.viewChange(target)
    })

    $('.calendar .news__filters form').on('submit', (e) => {
      e.preventDefault()

      const target = $(e.currentTarget)
      const currentView = $('.event-views__button.active').data('view')
      const query = target.find('input').val()
      this.filters.query = query

      if (currentView === 'listMonth') {
        this.listView.next().fadeIn(100)
        $.ajax({
          url: 'events.js',
          data: this.filters,
          success: () => {
            this.listView.next().fadeOut(300)
          },
        })
      } else {
        this.calendar.refetchEvents()
        this.singleDay.next().fadeIn(100)

        $.ajax({
          url: `/events/day_events.js?day=${this.selectedDate}`,
          data: this.filters,
          success: () => {
            this.singleDay.next().fadeOut(300)
          },
        })
      }
    })

    $('.calendar .news__filters select').on('change', (e) => {
      const value = $(e.currentTarget).val()
      this.filters.category = value

      const currentView = $('.event-views__button.active').data('view')

      if (currentView === 'listMonth') {
        this.listView.next().fadeIn(100)
        $.ajax({
          url: 'events.js',
          data: this.filters,
          success: () => {
            this.listView.next().fadeOut(300)
          },
        })
      } else {
        this.calendar.refetchEvents()
        this.singleDay.next().fadeIn(100)

        $.ajax({
          url: `/events/day_events.js?day=${this.selectedDate}`,
          data: this.filters,
          success: () => {
            this.singleDay.next().fadeOut(300)
          },
        })
      }
    })
  }

  initCalendar() {
    this.calendar = new Calendar(this.calendarEl, {
      plugins: [dayGridPlugin, listPlugin],
      events: {
        url: '/events.json',
        extraParams: this.filters,
      },
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: '',
        center: 'prev,title,next',
        right: '',
      },
      dayHeaderContent: (day) => {
        return day.text[0]
      },
      fixedWeekCount: false,
      showNonCurrentDates: false,
      eventSourceSuccess: () => {
        $('td').removeClass('fc-has-event')
      },
      viewDidMount: ({ view }) => {
        $('.fc-toolbar-title').text(view.title)

        $('.fc-day-today .fc-daygrid-day-number').addClass('active')
        this.singleDay.next().fadeOut(300)

        if (!this.pastView || this.pastView === 'listMonth') {
          this.singleDay.next().fadeIn(100)

          $.ajax({
            url: `/events/day_events.js?day=${this.selectedDate}`,
            success: () => {
              this.singleDay.next().fadeOut(300)
            },
          })

          this.pastView = view.type
        } else if (this.pastView === 'dayGridMonth') {
          this.listView.next().fadeIn(100)

          $.ajax({
            url: `/events.js`,
            data: {
              begin_date: moment(view.currentStart).toISOString(),
              end_date: moment(view.currentEnd).toISOString(),
              query: this.filters.query,
              category: this.filters.category,
            },
            success: () => {
              this.listView.next().fadeOut(300)
            },
          })
        }
      },
      datesSet: (dates) => {
        $('.fc-toolbar-title').text(dates.view.title)

        if (dates.view.type === 'listMonth') {
          $.ajax({
            url: `/events.js`,
            data: {
              begin_date: moment(dates.start).toISOString(),
              end_date: moment(dates.end).toISOString(),
              query: this.filters.query,
              category: this.filters.category,
            },
            success: () => {
              this.listView.next().fadeOut(300)
            },
          })
        }
      },
      eventDidMount: (data) => {
        const startString = moment(data.event.start).format('YYYY-MM-DD')
        $(`td[data-date="${startString}"]`).addClass('fc-has-event')
      },
      navLinks: true,
      navLinkDayClick: (date, e) => {
        this.selectedDate = moment(date).format()
        this.singleDay.next().fadeIn(100)

        $.ajax({
          url: `/events/day_events.js?day=${this.selectedDate}`,
          data: this.filters,
          success: () => {
            this.singleDay.next().fadeOut(300)
          },
        })

        $('.fc-daygrid-day-number').removeClass('active')
        e.currentTarget.classList.add('active')
      },
    })

    this.calendar.render()

    $('.fc-daygrid-day-number').on('click', (e) => {
      $('.fc-daygrid-day-number').removeClass('active')
      e.currentTarget.classList.add('active')
    })

    $('.fc-prev-button, .fc-next-button').on('click', (e) => {
      if ($('.events-calendar').hasClass('list-view')) {
        this.listView.next().fadeIn(100)
      }
    })
  }

  viewChange(target) {
    if (!target.hasClass('active')) {
      this.viewToggle.removeClass('active')
      target.addClass('active')

      const targetView = target.data('view')

      $('.events-calendar').removeClass('event-results')

      if (targetView === 'listMonth') {
        $('.events-calendar').addClass('list-view')
      } else {
        $('.events-calendar').removeClass('list-view')
      }

      this.showLoadingSpinners()
      this.calendar.changeView(targetView)
    }
  }

  showLoadingSpinners() {
    if ($('.events-calendar').hasClass('list-view')) {
      this.listView.next().fadeIn(100)
    } else {
      this.singleDay.next().fadeIn(100)
    }
  }
}
